<template>
    <div class="shipping-box">
        <form
                :action="url"
                class="d-flex flex-column justify-content-center form-container"
                id="shipping-form"
                method="POST"
                :id="forms.shippingForm.shippingAddress.dynamicHtmlName"
                :name="forms.shippingForm.shippingAddress.dynamicHtmlName"
            >
            <template v-if="isMobile">
                <div class="row">
                    <!-- nome -->
                    <div class="col-6" v-if="type==='CPF'">
                        <div class="form-group">
                            <input
                                required
                                autofocus
                                id="firstName"
                                type="text"
                                placeholder="Nome"
                                class="gray-card-form form-control"
                                autocomplete="shipping given-name"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.firstName.htmlName]"
                                :pattern="forms.shippingForm.shippingAddress.addressFields.firstName.regEx"
                                maxlength="50"
                                :minLength="forms.shippingForm.shippingAddress.addressFields.firstName.minLength"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_firstName}"
                            >
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_firstName" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_firstName }}</div>
                        </div>
                    </div>
                    <!-- razao social -->
                    <div class="col-6" v-else>
                        <div class="form-group">
                            <input
                                required
                                autofocus
                                id="firstName"
                                type="text"
                                placeholder="Razão social"
                                class="gray-card-form form-control"
                                autocomplete="shipping given-name"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.firstName.htmlName]"
                                :pattern="forms.shippingForm.shippingAddress.addressFields.firstName.regEx"
                                :maxlength="forms.shippingForm.shippingAddress.addressFields.firstName.maxLength"
                                :minLength="forms.shippingForm.shippingAddress.addressFields.firstName.minLength"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_firstName}"
                            >
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_firstName" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_firstName }}</div>
                        </div>
                    </div>

                    <!-- sobrenome -->
                    <div class="col-6" v-if="type==='CPF'">
                        <div class="form-group">
                            <input
                                required
                                autofocus
                                id="lastName"
                                type="text"
                                placeholder="Sobrenome"
                                class="gray-card-form form-control"
                                autocomplete="shipping family-name"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.lastName.htmlName]"
                                :pattern="forms.shippingForm.shippingAddress.addressFields.lastName.regEx"
                                :maxlength="forms.shippingForm.shippingAddress.addressFields.lastName.maxLength"
                                :minLength="forms.shippingForm.shippingAddress.addressFields.lastName.minLength"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_lastName}"
                            >
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_lastName" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_lastName }}</div>
                        </div>
                    </div>
                    <!-- inscrição estadual -->
                    <div class="col-6" v-else>
                        <div class="form-group">
                            <input
                                required
                                autofocus
                                id="lastName"
                                type="text"
                                placeholder="Inscrição Estadual"
                                class="gray-card-form form-control"
                                autocomplete="shipping family-name"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.lastName.htmlName]"
                                maxlength="20"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_lastName}"
                            >
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_lastName" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_lastName }}</div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <!-- email -->
                    <div class="col-12">
                        <div class="form-group">
                            <input
                                required
                                autofocus
                                id="login-form-email"
                                name="loginEmail"
                                type="email"
                                placeholder="E-mail"
                                class="form-control required"
                                :class="{'error': errors.email}"
                                v-model="user.email"
                                aria-required="true"
                                aria-describedby="form-email-error"
                            >
                            <div v-show="errors.email" class="error-message">{{ errors.email }}</div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <!-- telefone -->
                    <div class="col-6">
                        <div class="form-group">
                            <input
                                required
                                autofocus
                                id="phone"
                                type="tel"
                                placeholder="Celular"
                                class="gray-card-form form-control phoneNumberCheckout"
                                autocomplete="shipping tel"
                                v-mask="'(##) #####-####'"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.phone.htmlName]"
                                :pattern="forms.shippingForm.shippingAddress.addressFields.phone.regEx"
                                :maxlength="forms.shippingForm.shippingAddress.addressFields.phone.maxLength"
                                :minLength="forms.shippingForm.shippingAddress.addressFields.phone.minLength"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_phone}"
                            >
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_phone" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_phone }}</div>
                        </div>
                    </div>

                    <!-- zip code -->
                    <div class="col-6">
                        <div class="form-group">
                            <input
                                required
                                autofocus
                                id="zipCode"
                                type="text"
                                placeholder="CEP"
                                class="gray-card-form form-control"
                                autocomplete="shipping postal-code"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.postalCode.htmlName]"
                                v-mask="'#####-###'"
                                :pattern="forms.shippingForm.shippingAddress.addressFields.postalCode.regEx"
                                :maxlength="forms.shippingForm.shippingAddress.addressFields.postalCode.maxLength"
                                :minLength="forms.shippingForm.shippingAddress.addressFields.postalCode.minLength"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_postalCode}"
                            >
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_postalCode" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_postalCode }}</div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <!-- rua -->
                    <div class="col-12">
                        <div class="form-group">
                            <input
                                required
                                autofocus
                                id="address1"
                                type="text"
                                placeholder="Rua"
                                class="gray-card-form form-control"
                                autocomplete="shipping address-line1"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.address1.htmlName]"
                                :pattern="forms.shippingForm.shippingAddress.addressFields.address1.regEx"
                                :maxlength="forms.shippingForm.shippingAddress.addressFields.address1.maxLength"
                                :minLength="forms.shippingForm.shippingAddress.addressFields.address1.minLength"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_address1}"
                            >
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_address1" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_address1 }}</div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <!-- numero -->
                    <div class="col-5 colNumber" >
                        <div class="form-group">
                            <input
                                required
                                id="addressNumber"
                                type="number"
                                placeholder="Número"
                                class="gray-card-form form-control"
                                autocomplete="address-number"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.at_address_number.htmlName]"
                                :pattern="forms.shippingForm.shippingAddress.addressFields.at_address_number.regEx"
                                :maxlength="forms.shippingForm.shippingAddress.addressFields.at_address_number.maxLength"
                                :minLength="forms.shippingForm.shippingAddress.addressFields.at_address_number.minLength"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_at__address__number}"
                            >
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_at__address__number" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_at__address__number }}</div>
                        </div>
                    </div>

                    <!-- complemento -->
                    <div class="col-7">
                        <div class="form-group">
                            <input
                                required
                                id="address2"
                                type="text"
                                placeholder="Complemento"
                                class="gray-card-form form-control"
                                autocomplete="shipping address-line2"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.address2.htmlName]"
                                :pattern="forms.shippingForm.shippingAddress.addressFields.address2.regEx"
                                :maxlength="forms.shippingForm.shippingAddress.addressFields.address2.maxLength"
                                :minLength="forms.shippingForm.shippingAddress.addressFields.address2.minLength"
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <!-- cidade -->
                    <div class="col-6 colCity">
                        <div class="form-group">
                            <input
                                required
                                id="city"
                                type="text"
                                placeholder="Cidade"
                                class="gray-card-form form-control"
                                autocomplete="address-level2"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.city.htmlName]"
                                :pattern="forms.shippingForm.shippingAddress.addressFields.city.regEx"
                                :maxlength="forms.shippingForm.shippingAddress.addressFields.city.maxLength"
                                :minLength="forms.shippingForm.shippingAddress.addressFields.city.minLength"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_city}"
                            >
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_city" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_city }}</div>
                        </div>
                    </div>
                    <!-- estado -->
                    <div class="col-6">
                        <div class="form-group">
                            <select
                                required class="gray-card-form"
                                id="state"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.states.stateCode.htmlName]"
                                autocomplete="address-level1"
                                style="width: 100%;border: 1px solid #ced4da;"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_states_stateCode}"
                            >
                                <option id="" value="" disabled selected></option>
                                <option
                                    v-for="(state, index) in forms.shippingForm.shippingAddress.addressFields.states.stateCode.options"
                                    :key="index"
                                    :id="state.id"
                                    :value="state.htmlValue"
                                    :selected="state.selected"
                                >
                                {{ state.htmlValue }}
                                </option>
                            </select>
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_states_stateCode" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_states_stateCode }}</div>
                        </div>
                    </div>
                </div>

            </template>

            <template v-else>
                <div class="row">
                    <!-- email -->
                    <div class="col-6">
                        <div class="form-group">
                            <input
                                required
                                autofocus
                                id="login-form-email"
                                name="loginEmail"
                                type="email"
                                placeholder="E-mail"
                                class="form-control required"
                                :class="{'error': errors.email}"
                                v-model="user.email"
                                aria-required="true"
                                aria-describedby="form-email-error"
                            >
                            <div v-show="errors.email" class="error-message">{{ errors.email }}</div>
                        </div>
                    </div>

                    <!-- celular -->
                    <div class="col-6">
                        <div class="form-group">
                            <input
                                required
                                autofocus
                                id="phone"
                                type="tel"
                                placeholder="Celular"
                                class="gray-card-form form-control phoneNumberCheckout"
                                autocomplete="shipping tel"
                                v-mask="'(##) #####-####'"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.phone.htmlName]"
                                :pattern="forms.shippingForm.shippingAddress.addressFields.phone.regEx"
                                :maxlength="forms.shippingForm.shippingAddress.addressFields.phone.maxLength"
                                :minLength="forms.shippingForm.shippingAddress.addressFields.phone.minLength"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_phone}"
                            >
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_phone" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_phone }}</div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <!-- nome -->
                    <div class="col-6" v-if="type==='CPF'">
                        <div class="form-group">
                            <input
                                required
                                autofocus
                                id="firstName"
                                type="text"
                                placeholder="Nome"
                                class="gray-card-form form-control"
                                autocomplete="shipping given-name"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.firstName.htmlName]"
                                :pattern="forms.shippingForm.shippingAddress.addressFields.firstName.regEx"
                                maxlength="50"
                                :minLength="forms.shippingForm.shippingAddress.addressFields.firstName.minLength"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_firstName}"
                            >
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_firstName" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_firstName }}</div>
                        </div>
                    </div>
                    <!-- razao social -->
                    <div class="col-6" v-else>
                        <div class="form-group">
                            <input
                                required
                                autofocus
                                id="firstName"
                                type="text"
                                placeholder="Razão social"
                                class="gray-card-form form-control"
                                autocomplete="shipping given-name"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.firstName.htmlName]"
                                :pattern="forms.shippingForm.shippingAddress.addressFields.firstName.regEx"
                                :maxlength="forms.shippingForm.shippingAddress.addressFields.firstName.maxLength"
                                :minLength="forms.shippingForm.shippingAddress.addressFields.firstName.minLength"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_firstName}"
                            >
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_firstName" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_firstName }}</div>
                        </div>
                    </div>

                    <!-- sobrenome -->
                    <div class="col-6" v-if="type==='CPF'">
                        <div class="form-group">
                            <input
                                required
                                autofocus
                                id="lastName"
                                type="text"
                                placeholder="Sobrenome"
                                class="gray-card-form form-control"
                                autocomplete="shipping family-name"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.lastName.htmlName]"
                                :pattern="forms.shippingForm.shippingAddress.addressFields.lastName.regEx"
                                :maxlength="forms.shippingForm.shippingAddress.addressFields.lastName.maxLength"
                                :minLength="forms.shippingForm.shippingAddress.addressFields.lastName.minLength"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_lastName}"
                            >
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_lastName" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_lastName }}</div>
                        </div>
                    </div>
                    <!-- inscrição estadual -->
                    <div class="col-6" v-else>
                        <div class="form-group">
                            <input
                                required
                                autofocus
                                id="lastName"
                                type="text"
                                placeholder="Inscrição Estadual"
                                class="gray-card-form form-control"
                                autocomplete="shipping family-name"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.lastName.htmlName]"
                                maxlength="20"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_lastName}"
                            >
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_lastName" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_lastName }}</div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <!-- zip code -->
                    <div class="col-3">
                        <div class="form-group">
                            <input
                                required
                                autofocus
                                id="zipCode"
                                type="text"
                                placeholder="CEP"
                                class="gray-card-form form-control"
                                autocomplete="shipping postal-code"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.postalCode.htmlName]"
                                v-mask="'#####-###'"
                                :pattern="forms.shippingForm.shippingAddress.addressFields.postalCode.regEx"
                                :maxlength="forms.shippingForm.shippingAddress.addressFields.postalCode.maxLength"
                                :minLength="forms.shippingForm.shippingAddress.addressFields.postalCode.minLength"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_postalCode}"
                            >
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_postalCode" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_postalCode }}</div>
                        </div>
                    </div>

                    <!-- rua -->
                    <div class="col-7">
                        <div class="form-group">
                            <input
                                required
                                autofocus
                                id="address1"
                                type="text"
                                placeholder="Rua"
                                class="gray-card-form form-control"
                                autocomplete="shipping address-line1"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.address1.htmlName]"
                                :pattern="forms.shippingForm.shippingAddress.addressFields.address1.regEx"
                                :maxlength="forms.shippingForm.shippingAddress.addressFields.address1.maxLength"
                                :minLength="forms.shippingForm.shippingAddress.addressFields.address1.minLength"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_address1}"
                            >
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_address1" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_address1 }}</div>
                        </div>
                    </div>

                    <!-- numero e complemento -->
                    <div class="col-2 colNumber" >
                        <div class="form-group">
                            <input
                                required
                                id="addressNumber"
                                type="number"
                                placeholder="Número"
                                class="gray-card-form form-control"
                                autocomplete="address-number"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.at_address_number.htmlName]"
                                :pattern="forms.shippingForm.shippingAddress.addressFields.at_address_number.regEx"
                                :maxlength="forms.shippingForm.shippingAddress.addressFields.at_address_number.maxLength"
                                :minLength="forms.shippingForm.shippingAddress.addressFields.at_address_number.minLength"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_at__address__number}"
                            >
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_at__address__number" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_at__address__number }}</div>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <!-- complemento -->
                    <div class="col-3">
                        <div class="form-group">
                            <input
                                required
                                id="address2"
                                type="text"
                                placeholder="Complemento"
                                class="gray-card-form form-control"
                                autocomplete="shipping address-line2"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.address2.htmlName]"
                                :pattern="forms.shippingForm.shippingAddress.addressFields.address2.regEx"
                                :maxlength="forms.shippingForm.shippingAddress.addressFields.address2.maxLength"
                                :minLength="forms.shippingForm.shippingAddress.addressFields.address2.minLength"
                            >
                        </div>
                    </div>
                    <!-- cidade -->
                    <div class="col-7 colCity">
                        <div class="form-group">
                            <input
                                required
                                id="city"
                                type="text"
                                placeholder="Cidade"
                                class="gray-card-form form-control"
                                autocomplete="address-level2"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.city.htmlName]"
                                :pattern="forms.shippingForm.shippingAddress.addressFields.city.regEx"
                                :maxlength="forms.shippingForm.shippingAddress.addressFields.city.maxLength"
                                :minLength="forms.shippingForm.shippingAddress.addressFields.city.minLength"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_city}"
                            >
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_city" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_city }}</div>
                        </div>
                    </div>
                    <!-- estado -->
                    <div class="col-2">
                        <div class="form-group">
                            <select
                                required class="gray-card-form"
                                id="state"
                                v-model="addressModel[forms.shippingForm.shippingAddress.addressFields.states.stateCode.htmlName]"
                                autocomplete="address-level1"
                                style="width: 100%;border: 1px solid #ced4da;"
                                :class="{'error': errors.dwfrm_shipping_shippingAddress_addressFields_states_stateCode}"
                            >
                                <option id="" value="" disabled selected></option>
                                <option
                                    v-for="(state, index) in forms.shippingForm.shippingAddress.addressFields.states.stateCode.options"
                                    :key="index"
                                    :id="state.id"
                                    :value="state.htmlValue"
                                    :selected="state.selected"
                                >
                                {{ state.htmlValue }}
                                </option>
                            </select>
                            <div v-show="errors.dwfrm_shipping_shippingAddress_addressFields_states_stateCode" class="error-message">{{ errors.dwfrm_shipping_shippingAddress_addressFields_states_stateCode }}</div>
                        </div>
                    </div>
                </div>

            </template>
        </form>
        <div class="row">
            <button
                class="newShippingAddress"
                type="button"
                @click="changeShippingAddress()">
                Trocar endereço de entrega
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import routes from '../../../api/routes';

    export default {
        props:['errors',  'user', 'isMobile', 'type'],
        data() {
            return {
                addressModel: {
                    'dwfrm_shipping_shippingAddress_addressFields_firstName': '',
                    'dwfrm_shipping_shippingAddress_addressFields_lastName': '',
                    'dwfrm_shipping_shippingAddress_addressFields_phone': '',
                    'dwfrm_shipping_shippingAddress_addressFields_postalCode': '',
                    'dwfrm_shipping_shippingAddress_addressFields_address1': '',
                    'dwfrm_shipping_shippingAddress_addressFields_address2': '',
                    'dwfrm_shipping_shippingAddress_addressFields_at__address__district': '',
                    'dwfrm_shipping_shippingAddress_addressFields_at__address__number': '',
                    'dwfrm_shipping_shippingAddress_addressFields_city': '',
                    'dwfrm_shipping_shippingAddress_addressFields_states_stateCode': '',
                },
                url: routes.checkout.confirmShipping,
                firstPopulate: false,
                urlHome: routes.home.show
            }
        },
        mounted(){
            this.populateAddressModel();
            this.$store.commit('checkout/setAddressModel', this.addressModel);
        },
        computed: {
            ...mapGetters('checkout', {
                forms: 'forms',
                customer: 'customer',
                addressModal: 'addressModal'
            }),
            ...mapGetters('account', {
                logged: 'logged',
            })
        },
        methods: {
            populateAddressModel(){
                var target = {
                    postalCode: document.getElementById("zipCode"),
                    address: document.getElementById("address1"),
                    // district: document.getElementById("district"),
                    city: document.getElementById("city"),
                    stateCode: document.getElementById("state"),
                }

                if(this.logged){
                    this.addressModel['dwfrm_shipping_shippingAddress_addressFields_firstName'] = this.customer.profile.firstName
                    this.addressModel['dwfrm_shipping_shippingAddress_addressFields_lastName'] = this.customer.profile.stateRegistration || this.customer.profile.lastName
                    this.addressModel['dwfrm_shipping_shippingAddress_addressFields_phone'] = this.customer.profile.phone
                }

                this.addressModel['dwfrm_shipping_shippingAddress_addressFields_postalCode'] = this.addressModal.postalCode
                this.addressModel['dwfrm_shipping_shippingAddress_addressFields_address1'] = this.addressModal.address
                this.addressModel['dwfrm_shipping_shippingAddress_addressFields_address2'] = this.addressModal.address2 || ''
                this.addressModel['dwfrm_shipping_shippingAddress_addressFields_at__address__number'] = this.addressModal.number || ''
                this.addressModel['dwfrm_shipping_shippingAddress_addressFields_at__address__district'] = this.addressModal.district
                this.addressModel['dwfrm_shipping_shippingAddress_addressFields_city'] = this.addressModal.city
                this.addressModel['dwfrm_shipping_shippingAddress_addressFields_states_stateCode'] = this.addressModal.stateCode
                this.firstPopulate = false;

                target.postalCode.setAttribute("disabled", 'disabled');
                target.address.setAttribute("disabled", 'disabled');
                target.city.setAttribute("disabled", 'disabled');
                target.stateCode.setAttribute("disabled", 'disabled');
                // target.district.setAttribute("disabled", 'disabled');
            },
            changeShippingAddress() {
                // console.log("🚀 ~ changeShippingAddress");
                localStorage.setItem('openCEPModal', '#cepModal');
                window.location=this.urlHome;
            },
        },
        watch: {
            addressModel: {
                handler(newAddressModel, oldAddressModel) {
                    this.$root.$refs.ShippingStep.contactInfoModel['dwfrm_billing_contactInfoFields_phone'] = newAddressModel['dwfrm_shipping_shippingAddress_addressFields_phone'];
                    this.$store.commit('checkout/setAddressModel', newAddressModel);
                },
                deep: true,
            },
            'user.email'(newVal){
                this.$store.commit('account/setEmail', this.user.email)
            },
        },
    }
</script>


<style lang="scss" scoped>
.shipping-box{
    .row {
        margin-right: -5px !important;
        margin-left: -5px !important;

        .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
            padding-right: 5px !important;
            padding-left: 5px !important;
            padding-top: 5px !important;
            padding-bottom: 5px !important;
        }
    }
}
</style>