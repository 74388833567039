<template>
    <transition name="slide-down">
        <div v-if="showModal" class="quotation-body__container expanded-modal">
            <div class="expanded-modal-content">
                <!-- multiseller -->
                <template v-if="type === 'multiseller'">
                    <div class="modal-body">
                        <div class="productList-container" v-for="seller in quotationDetails.sellers[0].sellers">
                            <template v-for="shipment in seller.shipments">
                                <div class="header-title__store">
                                    <p class="sz-text--large-resp bold store-name__title">{{ shipment.sellerName }}</p>
                                </div>

                                <div class="productList-table-header">
                                        <p class="text-center start">Produtos</p>
                                        <p class="text-center d-none d-lg-flex">Quantidade</p>
                                        <p class="text-center d-lg-none">Qtd</p>
                                        <p class="text-center">Preço</p>
                                        <p class="text-center d-none d-lg-flex">Entrega</p>
                                        <p class="text-center minWidth"> Total </p>
                                </div>
                                <div style="gap: 12px; display: flex; flex-direction: column;">
                                    <div class="productList-table" v-for="product in seller.items">
                                        <div class="productDetail" v-if="product.images">
                                            <img class="img-fluid"
                                                :src="product.images.small[0].url"
                                                :alt="product.images.small[0].alt"
                                                :title="product.images.small[0].title"
                                                :onerror="'this.src=\'' + urlNoImg + '\''"/>
                                            <p class="product-name__table">{{ product.productName }}</p>
                                        </div>
                                        <p class="text-center">{{ product.quantity }}</p>
                                        <p class="text-center">{{ product.price.sales.formatted }}</p>
                                        <p class="text-center d-none d-lg-flex">{{ shipment.deliveryEstimateHour }}</p>
                                        <p class="text-center minWidth">{{ product.priceTotal.price }}</p>
                                    </div>
                                </div>
                            </template>

                            <div class="priceResume">
                                <p>Subtotal</p>
                                <span>{{ seller.totals.subTotalPriceFormatted }}</span>
                            </div>
                            <div class="priceResume">
                                <p>Frete</p>
                                <span class="shipping-total__freight">{{ seller.totals.shippingTotalPriceValue === 0
                                    ? 'Grátis' :
                                    seller.totals.shippingTotalPriceFormatted }}</span>
                            </div>
                            <div class="priceResume" v-if="seller.totals.shippingLevelDiscountTotal && seller.totals.shippingLevelDiscountTotal.value>0">
                                <p>Desconto do Frete</p>
                                <span>- {{ seller.totals.shippingLevelDiscountTotal.formatted }}</span>
                            </div>
                        </div>
                        <div class="priceResume total">
                            <p class="priceTotals">Total</p>
                            <span class="shipping-total__price">{{ quotationDetails.total ? quotationDetails.total.formatted : quotationDetails.sellers[0].total.formatted}}</span>
                            <!-- <span class="shipping-total__price">{{ quotationDetails.sellers[0].total.totalWithoutDiscount.formatted}}</span> -->
                        </div>
                    </div>
                </template>

                <!-- seller -->
                <template v-else>
                    <div class="modal-body">
                        <div class="productList-container">
                            <template v-for="shipment in quotationDetails.shipments">
                                <div class="header-title__store">
                                    <p class="sz-text--large-resp bold store-name__title">{{ shipment.sellerName }}</p>
                                </div>

                                <div class="productList-table-header">
                                        <p class="text-center start">Produtos</p>
                                        <p class="text-center d-none d-lg-flex">Quantidade</p>
                                        <p class="text-center d-lg-none">Qtd</p>
                                        <p class="text-center">Preço</p>
                                        <p class="text-center d-none d-lg-flex">Entrega</p>
                                        <p class="text-center minWidth"> Total </p>
                                </div>

                                <div style="gap: 12px; display: flex; flex-direction: column;">
                                    <div class="productList-table" v-for="product in quotationDetails.items.items">
                                        <div class="productDetail" v-if="product.images">
                                            <img class="img-fluid"
                                                :src="product.images.small[0].url"
                                                :alt="product.images.small[0].alt"
                                                :title="product.images.small[0].title"
                                                :onerror="'this.src=\'' + urlNoImg + '\''"/>
                                            <p class="product-name__table">{{ product.productName }}</p>
                                        </div>
                                        <p class="text-center">{{ product.quantity }}</p>
                                        <p class="text-center">{{ product.price.sales.formatted }}</p>
                                        <p class="text-center d-none d-lg-flex">{{ shipment.deliveryEstimateHour }}</p>
                                        <p class="text-center minWidth">{{ product.priceTotal.price }}</p>
                                    </div>
                                </div>
                            </template>

                            <div class="priceResume">
                                <p>Subtotal</p>
                                <span>{{ quotationDetails.totals.subTotal.formatted }}</span>
                            </div>
                            <div class="priceResume">
                                <p>Frete</p>
                                <span class="shipping-total__freight">{{
                                    quotationDetails.totals.totalShippingCost.formatted }}</span>
                            </div>
                            <div class="priceResume" v-if="quotationDetails.totals.shippingLevelDiscountTotal && quotationDetails.totals.shippingLevelDiscountTotal.value>0">
                                <p>Desconto do Frete</p>
                                <span>- {{ quotationDetails.totals.shippingLevelDiscountTotal.formatted }}</span>
                            </div>
                        </div>
                        <div class="priceResume total">
                            <p class="priceTotals">Total</p>
                            <span class="shipping-total__price">{{ quotationDetails.totals.grandTotal.formatted}}</span>
                        </div>

                        <div v-if="quotationDetails.invalidProducts.length >= 1">
                            <div class="productList-container">
                                <template>
                                    <div class="warning-advice__image">
                                        <img src="" alt="">
                                    </div> <span style="font-style: italic; font-size: 14px; color: #6A0000">Os produtos abaixo não estão
                                            disponíveis neste vendedor
                                        </span>
                                    <div class="invalidProducts__container">
                                        <div class="productList-table invalidProducts"
                                            v-for="product in quotationDetails.invalidProducts">
                                            <div class="productDetail">
                                                <!-- <img class="img-fluid"
                                                    :src="product.images.small[0].url"
                                                    :alt="product.images.small[0].alt"
                                                    :title="product.images.small[0].title"/> -->
                                                <div class="img-fluid"></div>
                                                <p class="sz-text--small product-name__table invalid_product-name">{{ product.name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </transition>
</template>

<script>
import routes from '../../../api/routes';
import { mapGetters } from 'vuex';

export default {
    props: ['quotationDetails', 'type', 'showModal'],
    data() {
        return {
            staticUrl: routes.urlStatic,
        }
    },
    computed: {
        ...mapGetters('checkout', {
            closeurl: 'closeurl'
        }),
        urlNoImg(){
            return this.staticUrl + 'noimage.png';
        }
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
    },
};
</script>
