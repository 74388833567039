var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "shipping-box" }, [
    _c(
      "form",
      {
        staticClass: "d-flex flex-column justify-content-center form-container",
        attrs: {
          action: _vm.url,
          id: "shipping-form",
          method: "POST",
          id: _vm.forms.shippingForm.shippingAddress.dynamicHtmlName,
          name: _vm.forms.shippingForm.shippingAddress.dynamicHtmlName,
        },
      },
      [
        _vm.isMobile
          ? [
              _c("div", { staticClass: "row" }, [
                _vm.type === "CPF"
                  ? _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.addressModel[
                                  _vm.forms.shippingForm.shippingAddress
                                    .addressFields.firstName.htmlName
                                ],
                              expression:
                                "addressModel[forms.shippingForm.shippingAddress.addressFields.firstName.htmlName]",
                            },
                          ],
                          staticClass: "gray-card-form form-control",
                          class: {
                            error:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_firstName,
                          },
                          attrs: {
                            required: "",
                            autofocus: "",
                            id: "firstName",
                            type: "text",
                            placeholder: "Nome",
                            autocomplete: "shipping given-name",
                            pattern:
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.firstName.regEx,
                            maxlength: "50",
                            minLength:
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.firstName.minLength,
                          },
                          domProps: {
                            value:
                              _vm.addressModel[
                                _vm.forms.shippingForm.shippingAddress
                                  .addressFields.firstName.htmlName
                              ],
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.addressModel,
                                _vm.forms.shippingForm.shippingAddress
                                  .addressFields.firstName.htmlName,
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.errors
                                    .dwfrm_shipping_shippingAddress_addressFields_firstName,
                                expression:
                                  "errors.dwfrm_shipping_shippingAddress_addressFields_firstName",
                              },
                            ],
                            staticClass: "error-message",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.errors
                                  .dwfrm_shipping_shippingAddress_addressFields_firstName
                              )
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.addressModel[
                                  _vm.forms.shippingForm.shippingAddress
                                    .addressFields.firstName.htmlName
                                ],
                              expression:
                                "addressModel[forms.shippingForm.shippingAddress.addressFields.firstName.htmlName]",
                            },
                          ],
                          staticClass: "gray-card-form form-control",
                          class: {
                            error:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_firstName,
                          },
                          attrs: {
                            required: "",
                            autofocus: "",
                            id: "firstName",
                            type: "text",
                            placeholder: "Razão social",
                            autocomplete: "shipping given-name",
                            pattern:
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.firstName.regEx,
                            maxlength:
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.firstName.maxLength,
                            minLength:
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.firstName.minLength,
                          },
                          domProps: {
                            value:
                              _vm.addressModel[
                                _vm.forms.shippingForm.shippingAddress
                                  .addressFields.firstName.htmlName
                              ],
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.addressModel,
                                _vm.forms.shippingForm.shippingAddress
                                  .addressFields.firstName.htmlName,
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.errors
                                    .dwfrm_shipping_shippingAddress_addressFields_firstName,
                                expression:
                                  "errors.dwfrm_shipping_shippingAddress_addressFields_firstName",
                              },
                            ],
                            staticClass: "error-message",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.errors
                                  .dwfrm_shipping_shippingAddress_addressFields_firstName
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
                _vm._v(" "),
                _vm.type === "CPF"
                  ? _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.addressModel[
                                  _vm.forms.shippingForm.shippingAddress
                                    .addressFields.lastName.htmlName
                                ],
                              expression:
                                "addressModel[forms.shippingForm.shippingAddress.addressFields.lastName.htmlName]",
                            },
                          ],
                          staticClass: "gray-card-form form-control",
                          class: {
                            error:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_lastName,
                          },
                          attrs: {
                            required: "",
                            autofocus: "",
                            id: "lastName",
                            type: "text",
                            placeholder: "Sobrenome",
                            autocomplete: "shipping family-name",
                            pattern:
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.lastName.regEx,
                            maxlength:
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.lastName.maxLength,
                            minLength:
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.lastName.minLength,
                          },
                          domProps: {
                            value:
                              _vm.addressModel[
                                _vm.forms.shippingForm.shippingAddress
                                  .addressFields.lastName.htmlName
                              ],
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.addressModel,
                                _vm.forms.shippingForm.shippingAddress
                                  .addressFields.lastName.htmlName,
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.errors
                                    .dwfrm_shipping_shippingAddress_addressFields_lastName,
                                expression:
                                  "errors.dwfrm_shipping_shippingAddress_addressFields_lastName",
                              },
                            ],
                            staticClass: "error-message",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.errors
                                  .dwfrm_shipping_shippingAddress_addressFields_lastName
                              )
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.addressModel[
                                  _vm.forms.shippingForm.shippingAddress
                                    .addressFields.lastName.htmlName
                                ],
                              expression:
                                "addressModel[forms.shippingForm.shippingAddress.addressFields.lastName.htmlName]",
                            },
                          ],
                          staticClass: "gray-card-form form-control",
                          class: {
                            error:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_lastName,
                          },
                          attrs: {
                            required: "",
                            autofocus: "",
                            id: "lastName",
                            type: "text",
                            placeholder: "Inscrição Estadual",
                            autocomplete: "shipping family-name",
                            maxlength: "20",
                          },
                          domProps: {
                            value:
                              _vm.addressModel[
                                _vm.forms.shippingForm.shippingAddress
                                  .addressFields.lastName.htmlName
                              ],
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.addressModel,
                                _vm.forms.shippingForm.shippingAddress
                                  .addressFields.lastName.htmlName,
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.errors
                                    .dwfrm_shipping_shippingAddress_addressFields_lastName,
                                expression:
                                  "errors.dwfrm_shipping_shippingAddress_addressFields_lastName",
                              },
                            ],
                            staticClass: "error-message",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.errors
                                  .dwfrm_shipping_shippingAddress_addressFields_lastName
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.email,
                          expression: "user.email",
                        },
                      ],
                      staticClass: "form-control required",
                      class: { error: _vm.errors.email },
                      attrs: {
                        required: "",
                        autofocus: "",
                        id: "login-form-email",
                        name: "loginEmail",
                        type: "email",
                        placeholder: "E-mail",
                        "aria-required": "true",
                        "aria-describedby": "form-email-error",
                      },
                      domProps: { value: _vm.user.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.user, "email", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.email,
                            expression: "errors.email",
                          },
                        ],
                        staticClass: "error-message",
                      },
                      [_vm._v(_vm._s(_vm.errors.email))]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "(##) #####-####",
                          expression: "'(##) #####-####'",
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.addressModel[
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.phone.htmlName
                            ],
                          expression:
                            "addressModel[forms.shippingForm.shippingAddress.addressFields.phone.htmlName]",
                        },
                      ],
                      staticClass:
                        "gray-card-form form-control phoneNumberCheckout",
                      class: {
                        error:
                          _vm.errors
                            .dwfrm_shipping_shippingAddress_addressFields_phone,
                      },
                      attrs: {
                        required: "",
                        autofocus: "",
                        id: "phone",
                        type: "tel",
                        placeholder: "Celular",
                        autocomplete: "shipping tel",
                        pattern:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .phone.regEx,
                        maxlength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .phone.maxLength,
                        minLength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .phone.minLength,
                      },
                      domProps: {
                        value:
                          _vm.addressModel[
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .phone.htmlName
                          ],
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.addressModel,
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .phone.htmlName,
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_phone,
                            expression:
                              "errors.dwfrm_shipping_shippingAddress_addressFields_phone",
                          },
                        ],
                        staticClass: "error-message",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.errors
                              .dwfrm_shipping_shippingAddress_addressFields_phone
                          )
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.addressModel[
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.postalCode.htmlName
                            ],
                          expression:
                            "addressModel[forms.shippingForm.shippingAddress.addressFields.postalCode.htmlName]",
                        },
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "#####-###",
                          expression: "'#####-###'",
                        },
                      ],
                      staticClass: "gray-card-form form-control",
                      class: {
                        error:
                          _vm.errors
                            .dwfrm_shipping_shippingAddress_addressFields_postalCode,
                      },
                      attrs: {
                        required: "",
                        autofocus: "",
                        id: "zipCode",
                        type: "text",
                        placeholder: "CEP",
                        autocomplete: "shipping postal-code",
                        pattern:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .postalCode.regEx,
                        maxlength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .postalCode.maxLength,
                        minLength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .postalCode.minLength,
                      },
                      domProps: {
                        value:
                          _vm.addressModel[
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .postalCode.htmlName
                          ],
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.addressModel,
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .postalCode.htmlName,
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_postalCode,
                            expression:
                              "errors.dwfrm_shipping_shippingAddress_addressFields_postalCode",
                          },
                        ],
                        staticClass: "error-message",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.errors
                              .dwfrm_shipping_shippingAddress_addressFields_postalCode
                          )
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.addressModel[
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.address1.htmlName
                            ],
                          expression:
                            "addressModel[forms.shippingForm.shippingAddress.addressFields.address1.htmlName]",
                        },
                      ],
                      staticClass: "gray-card-form form-control",
                      class: {
                        error:
                          _vm.errors
                            .dwfrm_shipping_shippingAddress_addressFields_address1,
                      },
                      attrs: {
                        required: "",
                        autofocus: "",
                        id: "address1",
                        type: "text",
                        placeholder: "Rua",
                        autocomplete: "shipping address-line1",
                        pattern:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .address1.regEx,
                        maxlength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .address1.maxLength,
                        minLength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .address1.minLength,
                      },
                      domProps: {
                        value:
                          _vm.addressModel[
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .address1.htmlName
                          ],
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.addressModel,
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .address1.htmlName,
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_address1,
                            expression:
                              "errors.dwfrm_shipping_shippingAddress_addressFields_address1",
                          },
                        ],
                        staticClass: "error-message",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.errors
                              .dwfrm_shipping_shippingAddress_addressFields_address1
                          )
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-5 colNumber" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.addressModel[
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.at_address_number.htmlName
                            ],
                          expression:
                            "addressModel[forms.shippingForm.shippingAddress.addressFields.at_address_number.htmlName]",
                        },
                      ],
                      staticClass: "gray-card-form form-control",
                      class: {
                        error:
                          _vm.errors
                            .dwfrm_shipping_shippingAddress_addressFields_at__address__number,
                      },
                      attrs: {
                        required: "",
                        id: "addressNumber",
                        type: "number",
                        placeholder: "Número",
                        autocomplete: "address-number",
                        pattern:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .at_address_number.regEx,
                        maxlength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .at_address_number.maxLength,
                        minLength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .at_address_number.minLength,
                      },
                      domProps: {
                        value:
                          _vm.addressModel[
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .at_address_number.htmlName
                          ],
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.addressModel,
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .at_address_number.htmlName,
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_at__address__number,
                            expression:
                              "errors.dwfrm_shipping_shippingAddress_addressFields_at__address__number",
                          },
                        ],
                        staticClass: "error-message",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.errors
                              .dwfrm_shipping_shippingAddress_addressFields_at__address__number
                          )
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-7" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.addressModel[
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.address2.htmlName
                            ],
                          expression:
                            "addressModel[forms.shippingForm.shippingAddress.addressFields.address2.htmlName]",
                        },
                      ],
                      staticClass: "gray-card-form form-control",
                      attrs: {
                        required: "",
                        id: "address2",
                        type: "text",
                        placeholder: "Complemento",
                        autocomplete: "shipping address-line2",
                        pattern:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .address2.regEx,
                        maxlength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .address2.maxLength,
                        minLength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .address2.minLength,
                      },
                      domProps: {
                        value:
                          _vm.addressModel[
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .address2.htmlName
                          ],
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.addressModel,
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .address2.htmlName,
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-6 colCity" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.addressModel[
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.city.htmlName
                            ],
                          expression:
                            "addressModel[forms.shippingForm.shippingAddress.addressFields.city.htmlName]",
                        },
                      ],
                      staticClass: "gray-card-form form-control",
                      class: {
                        error:
                          _vm.errors
                            .dwfrm_shipping_shippingAddress_addressFields_city,
                      },
                      attrs: {
                        required: "",
                        id: "city",
                        type: "text",
                        placeholder: "Cidade",
                        autocomplete: "address-level2",
                        pattern:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .city.regEx,
                        maxlength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .city.maxLength,
                        minLength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .city.minLength,
                      },
                      domProps: {
                        value:
                          _vm.addressModel[
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .city.htmlName
                          ],
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.addressModel,
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .city.htmlName,
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_city,
                            expression:
                              "errors.dwfrm_shipping_shippingAddress_addressFields_city",
                          },
                        ],
                        staticClass: "error-message",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.errors
                              .dwfrm_shipping_shippingAddress_addressFields_city
                          )
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.addressModel[
                                _vm.forms.shippingForm.shippingAddress
                                  .addressFields.states.stateCode.htmlName
                              ],
                            expression:
                              "addressModel[forms.shippingForm.shippingAddress.addressFields.states.stateCode.htmlName]",
                          },
                        ],
                        staticClass: "gray-card-form",
                        class: {
                          error:
                            _vm.errors
                              .dwfrm_shipping_shippingAddress_addressFields_states_stateCode,
                        },
                        staticStyle: {
                          width: "100%",
                          border: "1px solid #ced4da",
                        },
                        attrs: {
                          required: "",
                          id: "state",
                          autocomplete: "address-level1",
                        },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.addressModel,
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.states.stateCode.htmlName,
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", {
                          attrs: {
                            id: "",
                            value: "",
                            disabled: "",
                            selected: "",
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .states.stateCode.options,
                          function (state, index) {
                            return _c(
                              "option",
                              {
                                key: index,
                                attrs: { id: state.id },
                                domProps: {
                                  value: state.htmlValue,
                                  selected: state.selected,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(state.htmlValue) +
                                    "\n                            "
                                ),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_states_stateCode,
                            expression:
                              "errors.dwfrm_shipping_shippingAddress_addressFields_states_stateCode",
                          },
                        ],
                        staticClass: "error-message",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.errors
                              .dwfrm_shipping_shippingAddress_addressFields_states_stateCode
                          )
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]
          : [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.email,
                          expression: "user.email",
                        },
                      ],
                      staticClass: "form-control required",
                      class: { error: _vm.errors.email },
                      attrs: {
                        required: "",
                        autofocus: "",
                        id: "login-form-email",
                        name: "loginEmail",
                        type: "email",
                        placeholder: "E-mail",
                        "aria-required": "true",
                        "aria-describedby": "form-email-error",
                      },
                      domProps: { value: _vm.user.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.user, "email", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.email,
                            expression: "errors.email",
                          },
                        ],
                        staticClass: "error-message",
                      },
                      [_vm._v(_vm._s(_vm.errors.email))]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "(##) #####-####",
                          expression: "'(##) #####-####'",
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.addressModel[
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.phone.htmlName
                            ],
                          expression:
                            "addressModel[forms.shippingForm.shippingAddress.addressFields.phone.htmlName]",
                        },
                      ],
                      staticClass:
                        "gray-card-form form-control phoneNumberCheckout",
                      class: {
                        error:
                          _vm.errors
                            .dwfrm_shipping_shippingAddress_addressFields_phone,
                      },
                      attrs: {
                        required: "",
                        autofocus: "",
                        id: "phone",
                        type: "tel",
                        placeholder: "Celular",
                        autocomplete: "shipping tel",
                        pattern:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .phone.regEx,
                        maxlength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .phone.maxLength,
                        minLength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .phone.minLength,
                      },
                      domProps: {
                        value:
                          _vm.addressModel[
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .phone.htmlName
                          ],
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.addressModel,
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .phone.htmlName,
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_phone,
                            expression:
                              "errors.dwfrm_shipping_shippingAddress_addressFields_phone",
                          },
                        ],
                        staticClass: "error-message",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.errors
                              .dwfrm_shipping_shippingAddress_addressFields_phone
                          )
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _vm.type === "CPF"
                  ? _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.addressModel[
                                  _vm.forms.shippingForm.shippingAddress
                                    .addressFields.firstName.htmlName
                                ],
                              expression:
                                "addressModel[forms.shippingForm.shippingAddress.addressFields.firstName.htmlName]",
                            },
                          ],
                          staticClass: "gray-card-form form-control",
                          class: {
                            error:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_firstName,
                          },
                          attrs: {
                            required: "",
                            autofocus: "",
                            id: "firstName",
                            type: "text",
                            placeholder: "Nome",
                            autocomplete: "shipping given-name",
                            pattern:
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.firstName.regEx,
                            maxlength: "50",
                            minLength:
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.firstName.minLength,
                          },
                          domProps: {
                            value:
                              _vm.addressModel[
                                _vm.forms.shippingForm.shippingAddress
                                  .addressFields.firstName.htmlName
                              ],
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.addressModel,
                                _vm.forms.shippingForm.shippingAddress
                                  .addressFields.firstName.htmlName,
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.errors
                                    .dwfrm_shipping_shippingAddress_addressFields_firstName,
                                expression:
                                  "errors.dwfrm_shipping_shippingAddress_addressFields_firstName",
                              },
                            ],
                            staticClass: "error-message",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.errors
                                  .dwfrm_shipping_shippingAddress_addressFields_firstName
                              )
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.addressModel[
                                  _vm.forms.shippingForm.shippingAddress
                                    .addressFields.firstName.htmlName
                                ],
                              expression:
                                "addressModel[forms.shippingForm.shippingAddress.addressFields.firstName.htmlName]",
                            },
                          ],
                          staticClass: "gray-card-form form-control",
                          class: {
                            error:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_firstName,
                          },
                          attrs: {
                            required: "",
                            autofocus: "",
                            id: "firstName",
                            type: "text",
                            placeholder: "Razão social",
                            autocomplete: "shipping given-name",
                            pattern:
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.firstName.regEx,
                            maxlength:
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.firstName.maxLength,
                            minLength:
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.firstName.minLength,
                          },
                          domProps: {
                            value:
                              _vm.addressModel[
                                _vm.forms.shippingForm.shippingAddress
                                  .addressFields.firstName.htmlName
                              ],
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.addressModel,
                                _vm.forms.shippingForm.shippingAddress
                                  .addressFields.firstName.htmlName,
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.errors
                                    .dwfrm_shipping_shippingAddress_addressFields_firstName,
                                expression:
                                  "errors.dwfrm_shipping_shippingAddress_addressFields_firstName",
                              },
                            ],
                            staticClass: "error-message",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.errors
                                  .dwfrm_shipping_shippingAddress_addressFields_firstName
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
                _vm._v(" "),
                _vm.type === "CPF"
                  ? _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.addressModel[
                                  _vm.forms.shippingForm.shippingAddress
                                    .addressFields.lastName.htmlName
                                ],
                              expression:
                                "addressModel[forms.shippingForm.shippingAddress.addressFields.lastName.htmlName]",
                            },
                          ],
                          staticClass: "gray-card-form form-control",
                          class: {
                            error:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_lastName,
                          },
                          attrs: {
                            required: "",
                            autofocus: "",
                            id: "lastName",
                            type: "text",
                            placeholder: "Sobrenome",
                            autocomplete: "shipping family-name",
                            pattern:
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.lastName.regEx,
                            maxlength:
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.lastName.maxLength,
                            minLength:
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.lastName.minLength,
                          },
                          domProps: {
                            value:
                              _vm.addressModel[
                                _vm.forms.shippingForm.shippingAddress
                                  .addressFields.lastName.htmlName
                              ],
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.addressModel,
                                _vm.forms.shippingForm.shippingAddress
                                  .addressFields.lastName.htmlName,
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.errors
                                    .dwfrm_shipping_shippingAddress_addressFields_lastName,
                                expression:
                                  "errors.dwfrm_shipping_shippingAddress_addressFields_lastName",
                              },
                            ],
                            staticClass: "error-message",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.errors
                                  .dwfrm_shipping_shippingAddress_addressFields_lastName
                              )
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.addressModel[
                                  _vm.forms.shippingForm.shippingAddress
                                    .addressFields.lastName.htmlName
                                ],
                              expression:
                                "addressModel[forms.shippingForm.shippingAddress.addressFields.lastName.htmlName]",
                            },
                          ],
                          staticClass: "gray-card-form form-control",
                          class: {
                            error:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_lastName,
                          },
                          attrs: {
                            required: "",
                            autofocus: "",
                            id: "lastName",
                            type: "text",
                            placeholder: "Inscrição Estadual",
                            autocomplete: "shipping family-name",
                            maxlength: "20",
                          },
                          domProps: {
                            value:
                              _vm.addressModel[
                                _vm.forms.shippingForm.shippingAddress
                                  .addressFields.lastName.htmlName
                              ],
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.addressModel,
                                _vm.forms.shippingForm.shippingAddress
                                  .addressFields.lastName.htmlName,
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.errors
                                    .dwfrm_shipping_shippingAddress_addressFields_lastName,
                                expression:
                                  "errors.dwfrm_shipping_shippingAddress_addressFields_lastName",
                              },
                            ],
                            staticClass: "error-message",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.errors
                                  .dwfrm_shipping_shippingAddress_addressFields_lastName
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-3" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.addressModel[
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.postalCode.htmlName
                            ],
                          expression:
                            "addressModel[forms.shippingForm.shippingAddress.addressFields.postalCode.htmlName]",
                        },
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "#####-###",
                          expression: "'#####-###'",
                        },
                      ],
                      staticClass: "gray-card-form form-control",
                      class: {
                        error:
                          _vm.errors
                            .dwfrm_shipping_shippingAddress_addressFields_postalCode,
                      },
                      attrs: {
                        required: "",
                        autofocus: "",
                        id: "zipCode",
                        type: "text",
                        placeholder: "CEP",
                        autocomplete: "shipping postal-code",
                        pattern:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .postalCode.regEx,
                        maxlength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .postalCode.maxLength,
                        minLength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .postalCode.minLength,
                      },
                      domProps: {
                        value:
                          _vm.addressModel[
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .postalCode.htmlName
                          ],
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.addressModel,
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .postalCode.htmlName,
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_postalCode,
                            expression:
                              "errors.dwfrm_shipping_shippingAddress_addressFields_postalCode",
                          },
                        ],
                        staticClass: "error-message",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.errors
                              .dwfrm_shipping_shippingAddress_addressFields_postalCode
                          )
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-7" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.addressModel[
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.address1.htmlName
                            ],
                          expression:
                            "addressModel[forms.shippingForm.shippingAddress.addressFields.address1.htmlName]",
                        },
                      ],
                      staticClass: "gray-card-form form-control",
                      class: {
                        error:
                          _vm.errors
                            .dwfrm_shipping_shippingAddress_addressFields_address1,
                      },
                      attrs: {
                        required: "",
                        autofocus: "",
                        id: "address1",
                        type: "text",
                        placeholder: "Rua",
                        autocomplete: "shipping address-line1",
                        pattern:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .address1.regEx,
                        maxlength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .address1.maxLength,
                        minLength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .address1.minLength,
                      },
                      domProps: {
                        value:
                          _vm.addressModel[
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .address1.htmlName
                          ],
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.addressModel,
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .address1.htmlName,
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_address1,
                            expression:
                              "errors.dwfrm_shipping_shippingAddress_addressFields_address1",
                          },
                        ],
                        staticClass: "error-message",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.errors
                              .dwfrm_shipping_shippingAddress_addressFields_address1
                          )
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-2 colNumber" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.addressModel[
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.at_address_number.htmlName
                            ],
                          expression:
                            "addressModel[forms.shippingForm.shippingAddress.addressFields.at_address_number.htmlName]",
                        },
                      ],
                      staticClass: "gray-card-form form-control",
                      class: {
                        error:
                          _vm.errors
                            .dwfrm_shipping_shippingAddress_addressFields_at__address__number,
                      },
                      attrs: {
                        required: "",
                        id: "addressNumber",
                        type: "number",
                        placeholder: "Número",
                        autocomplete: "address-number",
                        pattern:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .at_address_number.regEx,
                        maxlength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .at_address_number.maxLength,
                        minLength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .at_address_number.minLength,
                      },
                      domProps: {
                        value:
                          _vm.addressModel[
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .at_address_number.htmlName
                          ],
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.addressModel,
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .at_address_number.htmlName,
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_at__address__number,
                            expression:
                              "errors.dwfrm_shipping_shippingAddress_addressFields_at__address__number",
                          },
                        ],
                        staticClass: "error-message",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.errors
                              .dwfrm_shipping_shippingAddress_addressFields_at__address__number
                          )
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-3" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.addressModel[
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.address2.htmlName
                            ],
                          expression:
                            "addressModel[forms.shippingForm.shippingAddress.addressFields.address2.htmlName]",
                        },
                      ],
                      staticClass: "gray-card-form form-control",
                      attrs: {
                        required: "",
                        id: "address2",
                        type: "text",
                        placeholder: "Complemento",
                        autocomplete: "shipping address-line2",
                        pattern:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .address2.regEx,
                        maxlength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .address2.maxLength,
                        minLength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .address2.minLength,
                      },
                      domProps: {
                        value:
                          _vm.addressModel[
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .address2.htmlName
                          ],
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.addressModel,
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .address2.htmlName,
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-7 colCity" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.addressModel[
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.city.htmlName
                            ],
                          expression:
                            "addressModel[forms.shippingForm.shippingAddress.addressFields.city.htmlName]",
                        },
                      ],
                      staticClass: "gray-card-form form-control",
                      class: {
                        error:
                          _vm.errors
                            .dwfrm_shipping_shippingAddress_addressFields_city,
                      },
                      attrs: {
                        required: "",
                        id: "city",
                        type: "text",
                        placeholder: "Cidade",
                        autocomplete: "address-level2",
                        pattern:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .city.regEx,
                        maxlength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .city.maxLength,
                        minLength:
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .city.minLength,
                      },
                      domProps: {
                        value:
                          _vm.addressModel[
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .city.htmlName
                          ],
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.addressModel,
                            _vm.forms.shippingForm.shippingAddress.addressFields
                              .city.htmlName,
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_city,
                            expression:
                              "errors.dwfrm_shipping_shippingAddress_addressFields_city",
                          },
                        ],
                        staticClass: "error-message",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.errors
                              .dwfrm_shipping_shippingAddress_addressFields_city
                          )
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-2" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.addressModel[
                                _vm.forms.shippingForm.shippingAddress
                                  .addressFields.states.stateCode.htmlName
                              ],
                            expression:
                              "addressModel[forms.shippingForm.shippingAddress.addressFields.states.stateCode.htmlName]",
                          },
                        ],
                        staticClass: "gray-card-form",
                        class: {
                          error:
                            _vm.errors
                              .dwfrm_shipping_shippingAddress_addressFields_states_stateCode,
                        },
                        staticStyle: {
                          width: "100%",
                          border: "1px solid #ced4da",
                        },
                        attrs: {
                          required: "",
                          id: "state",
                          autocomplete: "address-level1",
                        },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.addressModel,
                              _vm.forms.shippingForm.shippingAddress
                                .addressFields.states.stateCode.htmlName,
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", {
                          attrs: {
                            id: "",
                            value: "",
                            disabled: "",
                            selected: "",
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(
                          _vm.forms.shippingForm.shippingAddress.addressFields
                            .states.stateCode.options,
                          function (state, index) {
                            return _c(
                              "option",
                              {
                                key: index,
                                attrs: { id: state.id },
                                domProps: {
                                  value: state.htmlValue,
                                  selected: state.selected,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(state.htmlValue) +
                                    "\n                            "
                                ),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.errors
                                .dwfrm_shipping_shippingAddress_addressFields_states_stateCode,
                            expression:
                              "errors.dwfrm_shipping_shippingAddress_addressFields_states_stateCode",
                          },
                        ],
                        staticClass: "error-message",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.errors
                              .dwfrm_shipping_shippingAddress_addressFields_states_stateCode
                          )
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ],
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "button",
        {
          staticClass: "newShippingAddress",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.changeShippingAddress()
            },
          },
        },
        [_vm._v("\n            Trocar endereço de entrega\n        ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }